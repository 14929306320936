<template>
  <div class="panel-upload">
    <div
      class="panel-bg-file-img"
      v-bind:style="{
        backgroundImage: 'url(' + image + ')',
        '--aspect-ratio': imageRatio,
        '--border-color': isValid ? 'red' : 'var(--primary-color)',
      }"
    >
      <font-awesome-icon
        class="icon-delete"
        icon="times-circle"
        color="#FFFFFF"
        @click.prevent="deleteImage"
        v-if="image"
      />
      <font-awesome-icon
        icon="plus"
        color="#40a9d1"
        class="icon-add"
        v-if="!image"
      />
      <input
        type="file"
        v-on:change="onFileChange"
        :required="required"
        :name="name"
        ref="input"
        v-if="!image"
        accept="image/png, image/jpeg"
      />
    </div>
    <div class="mt-2" v-if="desc">
      <small>{{ desc }}</small>
    </div>
    <div v-if="v && v.$error" class="mt-1 mb-3">
      <span class="text-error" v-if="v.required == false">
        Please Upload images.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFile: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    urlkey: {
      required: false,
      type: String,
    },
    index: {
      required: false,
      type: Number,
    },
    optionIndex: {
      required: false,
      type: Number,
    },
    v: {
      required: false,
      type: Object,
    },
    isRatio: {
      required: false,
      default: false,
    },
    desc: {
      required: false,
    },
    index: {
      required: false,
    },
    imageIndex: {
      required: false,
    },
    withSize: {
      required: false,
    },
    imageRatio: {
      required: false,
      default: 1,
    },
    isValid: {
      required: false,
    },
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "application/pdf", ""],
        file: ["image/jpeg", "image/png", "application/pdf"],
        image: ["image/jpeg", "image/png"],
      },
      error: "",
      sortOrder: 0,
      data: this.dataFile,
      modalMessage: "",
      image: this.dataFile,
      size: { width: "", height: "" },
    };
  },
  created: function () {},
  watch: {
    dataFile(val) {
      this.image = val;
    },
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      var _validFileExtensions = ["image/jpeg", "image/png"];

      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(file.type) < 0) {
          this.$refs.input.value = "";
          this.warningAlert("Not Support File Type");
        } else if (file.size > 10000000) {
          this.$refs.input.value = "";

          this.warningAlert("File is To Large");
        } else {
          this.handleChangeFileImage(file);
        }
      } else {
        this.$refs.input.value = "";
      }
    },
    saveImage: async function (value) {
      if (this.withSize) {
        let image = await this.saveImagetoDb(value);

        this.image = image.filePath;
        this.size = { width: image.width, height: image.height };
      } else {
        this.image = await this.saveImagetoDb(value);
      }
      this.$emit(
        "handleChangeImage",
        this.image,
        this.index,
        this.imageIndex,
        this.size
      );
    },
    handleChangeFileImage: async function (value) {
      if (value) {
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this.value = reader.result;
          let im = new Image();
          im.src = reader.result;
          im.onload = async () => {
            if (im.width !== im.height && this.isRatio) {
              this.warningAlert("Please upload image with ratio 1:1 !");
            } else {
              this.saveImage(reader.result);
            }
          };
        };
      } else {
        this.value = "";
      }
    },
    deleteImage() {
      this.image = "";
      this.size = { width: "", height: "" };
      this.$emit(
        "handleChangeImage",
        this.image,
        this.index,
        this.imageIndex,
        this.size
      );
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      var data;
      if (this.withSize) {
        data = await this.$store.dispatch("ActionUploadImageSize", form);
      } else {
        await this.$store.dispatch("ActionUploadImage", form);
        data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      }
      if (data.result == 1) {
        return data.detail;
      }
    },

    changeIndex: function (value) {
      if (this.data) {
        this.$emit("changeIndex", value);
      }
    },
  },
};
</script>

<style scoped>
.panel-upload {
  position: relative;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}

.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #707070;
}
.label-file {
  top: 0px;
  position: absolute;
  right: 60px;
  margin-right: 5px;
}
.panel-bg-file-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  /* padding-bottom: 20%; */
  height: auto;
  aspect-ratio: var(--aspect-ratio);
  border: 2px dashed var(--border-color);
  /* border-radius: 6px; */
  width: 200px;
  cursor: pointer;
}
.icon-camera {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #afce38;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  opacity: 0;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
</style>
