<template>
  <div>
    <b-sidebar
      id="sidebar-action"
      ref="sidebarAction"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-action-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter">Privilege Use</b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div class="mt-3">
          <b-form-radio-group v-model="isSelected" @change="setAction">
            <b-row>
              <b-col
                ><b-form-radio :value="2">Unconditional</b-form-radio></b-col
              >
              <b-col><b-form-radio :value="1">Custom</b-form-radio></b-col>
            </b-row>
          </b-form-radio-group>
        </div>
        <div v-if="isSelected === 1">
          <b-row class="mt-3">
            <b-col md="4">
              <label>Privilege Type <span class="text-error">*</span></label>
            </b-col>
            <b-col md="8">
              <label>Show Information <span class="text-error">*</span></label>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-for="(item, index) in form" :key="index">
            <b-col md="4">
              <InputSelectAutomation
                v-bind:options="options"
                :value="item.action_type_id"
                :v-model="item.action_type_id"
                @onDataChange="selectActionId"
                :indexCondition="index"
                valueField="id"
                textField="name"
                :v="v.$each.$iter[index].action_type_id"
                :isValidate="v.$each.$iter[index].action_type_id.$error"
              />
            </b-col>
            <b-col md="8">
              <b-row v-if="item.action_type_id === 1">
                <b-col md="10">
                  <InputSelectAutomation
                    v-bind:options="redeemCodeList"
                    :noPleaseSelect="noPleaseSelect"
                    :v="v.$each.$iter[index].action_value"
                    :isValidate="v.$each.$iter[index].action_value.$error"
                    :value="item.action_value"
                    v-model="item.action_value"
                    :indexCondition="index"
                    @onDataChange="selectActionValue"
                    valueField="id"
                    textField="name"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeCondition(index)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="item.action_type_id === 2">
                <b-col md="7">
                  <b-form-input
                    v-model="item.action_value"
                    placeholder=""
                    type="number"
                    :class="
                      v.$each.$iter[index].action_value.$error
                        ? 'border-error'
                        : ''
                    "
                  ></b-form-input>
                  <div
                    v-if="v.$each.$iter[index].action_value.$error"
                    class="text-error"
                  >
                    Please input.
                  </div>
                </b-col>
                <b-col md="3">
                  <div class="mt-2">Point</div>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeCondition(index)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="item.action_type_id === 3 || item.action_type_id == 4"
              >
                <b-col md="10">
                  <InputSelectAutomation
                    v-bind:options="missionList"
                    :noPleaseSelect="noPleaseSelect"
                    :v="v.$each.$iter[index].action_value"
                    :isValidate="v.$each.$iter[index].action_value.$error"
                    :value="item.action_value"
                    v-model="item.action_value"
                    :indexCondition="index"
                    @onDataChange="selectActionValue"
                    valueField="id"
                    textField="name"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeCondition(index)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="mt-3">
            <button @click.prevent="addRuleAction" class="btn-add">
              <font-awesome-icon icon="plus-square" class="mr-2" />
              <span class="add-new">Create a New List</span>
            </button>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              @click.prevent="submitAction"
              block
              variant="light"
              class="text-body rounded-pill btn-main"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
export default {
  components: {
    InputSelectAutomation,
  },
  props: {
    dropdownAction: {
      required: true,
      type: Array,
    },

    redeemCodeList: {
      required: true,
      type: Array,
    },
    missionList: {
      required: false,
      type: Array,
      default: () => [],
    },
    form: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      noPleaseSelect: true,
      isSelected: this.selected,
      findPoint: false,
      options: this.dropdownAction,
    };
  },

  methods: {
    show() {
      this.options = this.dropdownAction;
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    selectActionId(...value) {
      if (value[0] === 2) {
        this.form[value[1]].pointId = 2;
        this.form[value[1]].pointName = "Point";
        this.findPoint = true;
      }
      if (value[0] == 6) this.form[value[1]].action_value = 0;
      this.form[value[1]].action_value = "";
      this.form[value[1]].action_type_id = value[0];
      this.form[value[1]].action_type_name = value[2];
      this.disabledOptions();
    },
    disabledOptions() {
      this.options = [
        ...this.options.map((a) => {
          return {
            ...a,
            disabled:
              a.id == 1 || a.id == 3
                ? false
                : this.form.find((el) => el.action_type_id == a.id)
                ? true
                : false,
          };
        }),
      ];
    },
    removeCondition(index) {
      this.form.splice(index, 1);
      const findTypePoint = this.form.find((el) => el.action_type_id == 2);
      if (findTypePoint) {
        this.findPoint = true;
      } else {
        this.findPoint = false;
      }
    },
    selectActionValue(...value) {
      this.form[value[1]].action_value = value[0];
    },
    addRuleAction() {
      if (this.form.length > 0) {
        const findActionPoint = this.form.findIndex(
          (el) => el.action_type_id == 2
        );
        if (findActionPoint >= 0) {
          this.form[findActionPoint].pointId = 2;
          this.form[findActionPoint].pointName = "Point";
          this.findPoint = true;
        } else {
          this.findPoint = false;
        }
        this.disabledOptions();
      }
      this.form.push({
        action_type_id: this.options[0].id,
        action_value: "",
        action_type_name: this.options[0].name,
      });
    },
    submitAction() {
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("settingAction", this.isSelected);
          this.hide();
        } else {
          this.v.$touch();
          if (this.v.$error) {
            return;
          }
          this.$emit("settingAction", this.isSelected);
          this.hide();
        }
      } else {
        this.$swal("Please specify privileges condition.", {
          icon: "warning",
        });
      }
    },
    setAction() {
      if (this.isSelected === 1) {
        if (this.form.length < 1) {
          this.form.push({
            action_type_id: this.options[0].id,
            action_value: "",
            action_type_name: this.options[0].name,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
</style>
