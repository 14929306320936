var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:!_vm.edit ? 'card-commu' : 'card-commu-edit'},[_c('div',{staticClass:"my-2 font-w-600"},[_vm._v(_vm._s(_vm.title))]),(_vm.$route.name != 'Automation Detail')?_c('span',[_vm._v(" Send Customers : "+_vm._s(_vm._f("numeral")(_vm.estimatedCustomer.userLine,"0,0")))]):_vm._e(),(
      _vm.estimatedCustomer.userSend > 0 && _vm.$route.name != 'Automation Detail'
    )?_c('span',[_vm._v("/"+_vm._s(_vm._f("numeral")(_vm.estimatedCustomer.userSend,"0,0")))]):_vm._e(),(!_vm.edit)?_c('div',{staticClass:"container-card b-white"},[_vm._m(0)]):[_c('div',{staticClass:"container-card scroll-content"},_vm._l((_vm.value),function(item,index){return _c('div',{key:index},[(item.communication_type_id === 3)?_c('div',[_vm._l((item.detail_list),function(line,i){return _c('div',{key:i},_vm._l((line.communication_value),function(list,i){return _c('div',{key:i,staticClass:"p-3"},[(list.value || list.image_url)?_c('div',{staticClass:"container-card-list mb-2"},[(
                    parseInt(line.message_type_id) === 8 ||
                    parseInt(line.message_type_id) === 9
                  )?_c('b-row',{attrs:{"cols":"6","cols-lg":"4"}},[_c('b-col',{staticClass:"mt-2 text-center px-0"},[_c('img',{staticClass:"crop-img",attrs:{"src":list.image_url}})])],1):_c('div',[(
                      parseInt(line.message_type_id) === 12 ||
                      parseInt(line.message_type_id) === 7
                    )?_c('div',{staticClass:"mt-2"},[_c('img',{staticClass:"crop-img",attrs:{"src":list.image_url}})]):_vm._e(),(parseInt(line.message_type_id) === 9)?_c('div',{staticClass:"mt-2 font-w-600"},[_vm._v(" IMAGE MAP ")]):_vm._e(),(list.value)?_c('div',[_vm._v(_vm._s(list.value))]):_vm._e()]),(line.format_list)?_c('div',_vm._l((line.format_list),function(format,j){return _c('div',{key:j,staticClass:"bg-button mt-2"},[_c('font-awesome-icon',{attrs:{"icon":"circle"}}),_vm._v(" "+_vm._s(format.option_name)+" ")],1)}),0):_vm._e()],1):_vm._e(),(
                  line.message_type_id == 14 || line.message_type_id == 13
                )?_c('div',_vm._l((line.format_list),function(formatList,n){return _c('div',{key:'image-' + n,staticClass:"card"},[(formatList.format_id == 3)?[_c('div',{staticClass:"p-3 text-center"},[_vm._v(" "+_vm._s(formatList.closing_text)+" ")])]:[(formatList.number_of_images == 2)?_c('div',[_c('b-row',{staticClass:"no-gutters",attrs:{"set":(_vm.imageList = formatList.details.filter(
                            (el) => el.format_detail_type == 2
                          ))}},_vm._l((_vm.imageList),function(image,n){return _c('b-col',{key:'banner-image' + n,attrs:{"sm":"6"}},[_c('b-img',{staticClass:"w-100 h-100",attrs:{"src":image.image}})],1)}),1)],1):(formatList.number_of_images == 3)?_c('div',[_c('b-row',{staticClass:"no-gutters",attrs:{"set":(_vm.imageList = formatList.details.filter(
                            (el) => el.format_detail_type == 2
                          ))}},[_c('b-col',{attrs:{"sm":"6"}},[_c('b-img',{staticClass:"w-100 h-100",attrs:{"src":_vm.imageList[0].image}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-img',{staticClass:"w-100 h-50",attrs:{"src":_vm.imageList[1].image}}),_c('b-img',{staticClass:"w-100 h-50",attrs:{"src":_vm.imageList[2].image}})],1),_c('b-col',{attrs:{"sm":"6"}})],1)],1):_c('div',[_c('b-img',{staticClass:"w-100",attrs:{"src":formatList.details.find(
                            (el) => el.format_detail_type == 2
                          ).image}})],1),_c('div',{staticClass:"p-3"},[(formatList.header_name)?_c('div',[_vm._v(" "+_vm._s(formatList.header_name)+" ")]):_vm._e(),(formatList.is_descriptions == 1)?_c('div',[_vm._v(" "+_vm._s(formatList.description)+" ")]):_vm._e(),(formatList.is_price == 1)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(formatList.price_currency)+_vm._s(_vm._f("numeral")(formatList.price,"0,0.00"))+" ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('u',[_vm._v(" "+_vm._s(formatList.details[0].action_title_name))])])]),(formatList.is_banner)?_c('div',{staticClass:"badge banner-badge",style:({
                        'background-color': `${formatList.color}`,
                        color: `${
                          formatList.color == 'black' ? 'white' : 'black'
                        }`,
                      })},[_vm._v(" "+_vm._s(formatList.banner)+" ")]):_vm._e()]],2)}),0):_vm._e(),(line.message_type_id == 15)?_c('div',_vm._l((line.format_list),function(formatList,n){return _c('div',{key:'image-' + n,staticClass:"card"},[_c('b-img',{staticClass:"w-100",attrs:{"src":formatList.details.find(
                        (el) => el.format_detail_type == 2
                      ).image}})],1)}),0):_vm._e(),(!_vm.disableStatus)?_c('div',{staticClass:"my-3 text-edit-communication"},[_c('button',{staticClass:"btn-edit mr-2",attrs:{"disabled":_vm.disableStatus},on:{"click":function($event){$event.preventDefault();return _vm.openSidebar.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"pencil-alt"}}),_c('span',{staticClass:"text-underline"},[_vm._v("Edit")])],1),(item.detail_list.length === 1)?_c('button',{staticClass:"btn-edit mr-2",attrs:{"disabled":_vm.disableStatus},on:{"click":function($event){$event.preventDefault();return _vm.deleteLine.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash-alt"}}),_c('span',{staticClass:"text-underline"},[_vm._v("Delete")])],1):_vm._e()]):_vm._e()])}),0)}),_c('button',{staticClass:"btn-edit mr-2",on:{"click":function($event){$event.preventDefault();return _vm.modalTestSend.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"paper-plane"}}),_c('span',{staticClass:"text-underline"},[_vm._v("Test Send")])],1)],2):_vm._e()])}),0)],(!_vm.disableStatus)?_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn-add",attrs:{"disabled":_vm.disableStatus},on:{"click":function($event){$event.preventDefault();return _vm.openSidebar.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"plus-square"}}),_c('span',{staticClass:"add-new"},[_vm._v("Create a New List")])],1)]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vertical-center"},[_c('div',[_vm._v("No Items")])])
}]

export { render, staticRenderFns }