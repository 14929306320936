<template>
  <div>
    <b-row>
      <b-col>
        <InputSelect
          title="Option Format"
          name="type"
          isRequired
          v-model="button.format_id"
          :options="buttonTypeList"
          valueField="id"
          textField="name"
          @onDataChange="handleChangeType"
        />
      </b-col>
      <b-col>
        <InputText
          textFloat="Sort Order"
          v-model="button.sort_order"
          placeholder="Sort Order"
          type="number"
          name="sortOrder"
        />
      </b-col>
    </b-row>

    <template>
      <b-row>
        <b-col>
          <InputText
            v-model="button.option_name"
            :isValidate="v.option_name.$error"
            :v="v.option_name"
            textFloat="Option Name"
            placeholder="Option Name"
            type="text"
            name="text"
            isRequired
            :lightText="true"
            :maxLength="40"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <InputText
            v-model="button.intent_name"
            textFloat="Action Name/Intent Name"
            :v="v.intent_name"
            placeholder="Action Name/Intent Name"
            :isValidate="v.intent_name.$error"
            type="text"
            name="text"
            isRequired
            :lightText="true"
            :maxLength="40"
          />
          <div v-if="v.intent_name.$anyError" class="text-error">กรุณากรอก</div>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputText,
    InputSelect,
  },
  props: {
    button: {
      required: true,
      type: Object,
    },
    buttonIndex: {
      required: false,
      type: Number,
    },
    v: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      isNewButton: false,
      buttonTypeList: [
        {
          id: 1,
          name: "Button",
        },
        // {
        //   id: 2,
        //   name: "Quick Reply",
        // },
      ],
    };
  },
  methods: {
    handleChangeType(value) {
      this.button.format_id = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  color: red;
}
</style>
