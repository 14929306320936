<template>
  <div class="">
    <b-row>
      <b-col cols="6">
        <InputSelect
          title="Type"
          name="MessageFormat"
          isRequired
          :options="replyTypeList"
          v-model="line.message_type_id"
          valueField="id"
          textField="name"
          @onDataChange="handleChangeType"
        />
      </b-col>
      <b-col cols="6">
        <InputText
          textFloat="Sort Order"
          placeholder="0"
          type="number"
          isRequired
          name="sortOrder"
          :v="vSortOrder"
          :isValidate="vSortOrder.$error"
          v-model="line.sort_order"
        />
      </b-col>
    </b-row>

    <div v-for="(resp, rindex) of line.communication_value" :key="rindex">
      <template v-if="parseInt(line.message_type_id) === 10">
        <TypeTextPanel
          :response="resp"
          :v="v.$each.$iter[rindex]"
          :variableItem="variableItem"
          :formMain="formMain"
        />
      </template>

      <!-- Type Text/Button -->
      <template v-if="parseInt(line.message_type_id) === 11">
      <TypeTextButtonPanel
          :response="line"
          :text="resp"
          :v="v.$each.$iter[rindex]"
          :vFormat="vFormat"
          :variableItem="variableItem"
          :formMain="formMain"
        />
      </template>

      <!-- Type Text/Image -->
      <template v-if="parseInt(line.message_type_id) === 12">
        <TypeTextImagePanel
          :response="line"
          :v="v.$each.$iter[rindex]"
          :text="resp"
          :variableItem="variableItem"
          :formMain="formMain"
        />
      </template>

      <!-- Type Text/Image/Button -->
      <template v-if="parseInt(line.message_type_id) === 7">
        <TypeTextImageButton
          :response="line"
          :v="v.$each.$iter[rindex]"
          :vFormat="vFormat"
          :text="resp"
          :variableItem="variableItem"
          :formMain="formMain"
        />
      </template>

      <!-- Type Custom -->
      <template v-if="parseInt(line.message_type_id) === 9">
        <TypeImage :response="line" :v="v.$each.$iter[rindex]" :text="resp" />
      </template>
      <template v-if="parseInt(line.message_type_id) === 13">
        <TypeCardMessage
          ref="cardTypeMessage"
          :response="line"
          :formIndex="sortOrder"
          :text="resp"
        />
      </template>
      <template v-if="parseInt(line.message_type_id) === 14">
        <TypeProductMessage
          ref="productTypeMessage"
          :response="line"
          :formIndex="sortOrder"
          :isShowSidebar="isShowSidebar"
          :text="resp"
        />
      </template>
      <template v-if="parseInt(line.message_type_id) === 15">
        <TypeClickAble
          ref="clickAble"
          :response="line"
          :v="v.$each.$iter[rindex]"
          :formIndex="sortOrder"
          :text="resp"
        />
      </template>
      <div v-if="parseInt(line.message_type_id) === 8">
        <TypeImagePanel
          :v="v.$each.$iter[rindex]"
          :response="line"
          :id="id"
          :dataImage="dataImage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TypeTextPanel from "@/components/automation/detail/LINE/TypeMessage/TypeTextPanel";
import TypeTextButtonPanel from "@/components/automation/detail/LINE/TypeMessage/TypeTextButtonPanel";
import TypeTextImagePanel from "@/components/automation/detail/LINE/TypeMessage/TypeTextImagePanel";
import TypeImagePanel from "@/components/automation/detail/LINE/TypeMessage/TypeImagePanel";
import TypeImage from "@/components/automation/detail/LINE/TypeMessage/TypeImage";
import TypeCardMessage from "@/components/automation/detail/LINE/TypeMessage/TypeCardMessage";
import TypeClickAble from "@/components/automation/detail/LINE/TypeMessage/TypeClickable";
import TypeProductMessage from "@/components/automation/detail/LINE/TypeMessage/TypeProductMessage";
import TypeTextImageButton from "@/components/automation/detail/LINE/TypeMessage/TypeTextImageButton";
export default {
  components: {
    TypeTextPanel,
    TypeTextButtonPanel,
    TypeTextImagePanel,
    TypeTextImageButton,
    TypeImagePanel,
    TypeCardMessage,
    TypeClickAble,
    TypeProductMessage,
    TypeImage,
  },
  props: {
    sortOrder: {
      required: true,
      type: Number,
    },
    line: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    replyTypeList: {
      required: true,
      type: Array,
    },
    vFormat: {
      required: false,
      type: Object,
    },
    vSortOrder: {
      required: false,
      type: Object,
    },
    dataImage: {
      required: true,
      type: Array,
    },
    id: {
      required: false,
      type: String,
    },
    variableItem: {
      required: true,
      type: Array,
    },
    isShowSidebar: {
      required: false,
      type: Boolean,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      responseType: 10,
      respLine: this.line,
    };
  },
  methods: {
    handleChangeType(value) {
      this.responseType = value;
      // Type Text
      if (parseInt(this.responseType) === 10) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: "",
              image_url: "",
            },
          ],
          format_list: [],
        };
        this.line.message_type_id = value;
        this.line.sort_order = content.sort_order;
        this.line.communication_value = content.communication_value;
        this.line.format_list = content.format_list;
      }
      // Type Text/Button
      else if (parseInt(this.responseType) === 11) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: "",
              image_url: "",
            },
          ],
          format_list: [
            {
              message_type_id: value,
              format_id: 1,
              option_name: "",
              sort_order: 1,
              intent_name: "",
            },
          ],
        };
        this.line.message_type_id = value;
        this.line.sort_order = content.sort_order;
        this.line.communication_value = content.communication_value;
        this.line.format_list = content.format_list;
      }

      // Type Text/Image
      else if (parseInt(this.responseType) === 12) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: "",
              image_url: "",
            },
          ],
          format_list: [],
        };
        this.line.message_type_id = value;
        this.line.sort_order = content.sort_order;
        this.line.communication_value = content.communication_value;
        this.line.format_list = content.format_list;
      }

      // Type Image
      else if (parseInt(this.responseType) === 8) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: null,
              image_url: null,
            },
          ],
          format_list: [],
        };
        this.line.message_type_id = value;
        this.line.sort_order = content.sort_order;
        this.line.communication_value = content.communication_value;
        this.line.format_list = content.format_list;
      } else if (parseInt(this.responseType) === 7) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: "",
              image_url: "",
            },
          ],
          format_list: [
            {
              message_type_id: value,
              format_id: 1,
              option_name: "",
              // action_name: "",
              intent_name: "",
              sort_order: 1,
            },
          ],
        };
        this.line.message_type_id = value;
        this.line.sort_order = content.sort_order;
        this.line.communication_value = content.communication_value;
        this.line.format_list = content.format_list;
      }

      // Type Text/Image/Button
      // else if (parseInt(this.responseType) === 7) {
      //   const content = {
      //     message_type_id: value,
      //     sort_order: this.sortOrder + 1,
      //     communication_value: [
      //       {
      //         message_type_id: value,
      //         value: "",
      //         image_url: "",
      //       },
      //     ],
      //     format_list: [
      //       {
      //         message_type_id: value,
      //         format_id: 1,
      //         option_name: "",
      //         intent_name: "",
      //         sort_order: 1,
      //       },
      //     ],
      //   };
      //   this.line = JSON.parse(JSON.stringify(content));
      // }

      // Type Custom
      else if (parseInt(this.responseType) === 9) {
        const content = {
          message_type_id: value,
          sort_order: this.sortOrder + 1,
          communication_value: [
            {
              message_type_id: value,
              value: "",
              image_url: "",
            },
          ],
          format_list: [],
        };
        this.line.message_type_id = value;
        this.line.sort_order = this.sortOrder + 1;
        this.line.format_list = content.format_list;
        this.line.communication_value = content.communication_value;
      } else if (value == 14) {
        this.line.communication_value[0].message_type_id = value;
        this.line.communication_value[0].value = "";
        this.line.communication_value[0].image_url = "";
        this.$refs.productTypeMessage[0].setShow(true);
      } else {
        this.line.communication_value[0].message_type_id = value;
        this.line.communication_value[0].value = "";
        this.line.communication_value[0].image_url = "";
      }

      // if (this.isEdit) {
      //   this.response.id == 0;
      // }
    },
    // handleChangeImageList(imageList){
    //   this.respLine.communication_value = imageList;
    // }
  },
};
</script>

<style lang="scss" scoped>
.button-box {
  background-color: #cdcae2;
  border-radius: 6px;
}
.text-main-color {
  color: #16274a;
}
.f-size-16 {
  font-size: 16px;
}
::v-deep .div-input {
  margin-bottom: 15px !important;
}
</style>
