<template>
  <div v-if="isShow">
    <b-row>
      <b-col md="12" class="content-between">
        <span class="text-main-color f-size-16 font-weight-bold mb-1"
          >Product Message</span
        >
        <span class="text-underline cursor-pointer" @click="addCardMessage"
          ><b>Add</b></span
        >
      </b-col>
    </b-row>

    <div class="card" v-for="(d, index) of data" :key="index">
      <div class="card-body">
        <div class="content-between">
          <span class="text-main-color f-size-16 mb-1"
            >Product Message # {{ index + 1 }}</span
          >
          <span
            class="text-underline cursor-pointer"
            @click="removeProductList(index)"
            v-if="data.length > 1"
            ><font-awesome-icon icon="trash-alt" class="ft-14 ml-2"
          /></span>
        </div>

        <b-row>
          <b-col cols="6">
            <InputSelect
              title="Number of Images"
              name="card-message-type"
              isRequired
              :options="optType"
              v-model="d.number_of_images"
              @onDataChange="handleNumberImageChange(d, index)"
          /></b-col>
          <div class="break-normal"></div>
          <b-col md="12" xl="6">
            <b-row>
              <template v-if="d.number_of_images <= 2">
                <b-col
                  cols="4"
                  v-for="(image, image_index) of imageList[index]"
                  :key="'image-' + image_index + index"
                >
                  <ImageUpload
                    class="mt-1"
                    v-bind:dataFile="image.image"
                    :index="index"
                    :imageIndex="image_index"
                    :withSize="true"
                    :imageRatio="'1.54/' + imageList[index].length"
                    :isValid="
                      $v.imageList.$each.$iter[index].$each.$iter[image_index]
                        .image.$error
                    "
                    @handleChangeImage="handleChangeImage"
                  />
                </b-col>
                <b-col cols="12" class="my-3"
                  ><small class="mb-2"
                    >(Recommend upload only file .png .jpg, aspect ratio 1.54:{{
                      imageList[index].length
                    }}
                    and less than 10 MB)</small
                  ></b-col
                >
              </template>
              <template v-else>
                <b-col
                  cols="4"
                  v-for="(image, image_index) of imageList[index]"
                  :key="'image+' + image_index + index"
                >
                  <ImageUpload
                    v-if="image_index < 1"
                    class="mt-1"
                    v-bind:dataFile="image.image"
                    :index="index"
                    :imageIndex="image_index"
                    :withSize="true"
                    imageRatio="1.54/2"
                    :isValid="
                      $v.imageList.$each.$iter[index].$each.$iter[image_index]
                        .image.$error
                    "
                    @handleChangeImage="handleChangeImage"
                  />
                </b-col>
                <b-col cols="12" class="my-3"
                  ><small class="mb-2"
                    >(Recommend upload only file .png .jpg, aspect ratio 1.54:2
                    and less than 10 MB)</small
                  ></b-col
                >
                <template v-for="(image, image_index) of imageList[index]">
                  <b-col
                    cols="4"
                    :key="'image/' + image_index + index"
                    v-if="image_index >= 1"
                  >
                    <ImageUpload
                      class="mt-1"
                      v-bind:dataFile="image.image"
                      :index="index"
                      :imageIndex="image_index"
                      :withSize="true"
                      imageRatio="1.54/1"
                      :isValid="
                        $v.imageList.$each.$iter[index].$each.$iter[image_index]
                          .image.$error
                      "
                      @handleChangeImage="handleChangeImage"
                    />
                  </b-col>
                </template>
                <b-col cols="12" class="my-3"
                  ><small class="mb-2"
                    >(Recommend upload only file .png .jpg, aspect ratio 1.54:1
                    and less than 10 MB)</small
                  ></b-col
                >
              </template>
            </b-row>

            <div
              class="text-error f-14"
              v-if="$v.imageList.$each.$iter[index].$error"
            >
              Please upload images.
            </div>
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <b-form-checkbox
              :id="'is_banner' + index + formIndex"
              class="mb-2"
              v-model="d.is_banner"
              :name="'is_banner' + index + formIndex"
              :value="1"
              :unchecked-value="0"
              @change="clearValueCheck($event, 'banner', d)"
            >
              <label
                :for="'is_banner' + index + formIndex"
                class="font-weight-bold mb-2 p-black"
                >Banner</label
              >
            </b-form-checkbox>
          </b-col>
          <b-col cols="6"> <label for="Color">Color</label> </b-col>
          <b-col cols="6">
            <InputText
              textFloat=""
              placeholder="Banner Text 12 Characters"
              type="text"
              v-model="d.banner"
              maxLength="12"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 12);'"
              name="banner"
              :disabled="d.is_banner == 0"
              :v="$v.data.$each.$iter[index].banner"
              :isValidate="$v.data.$each.$iter[index].banner.$error"
          /></b-col>
          <b-col cols="6">
            <div
              :class="[
                'd-flex p-1',
                { isValid: $v.data.$each.$iter[index].color.$error },
              ]"
              style="column-gap: 1.5rem"
            >
              <div
                class="picker-color shadow-sm"
                :style="`--color:${color.text}`"
                v-for="(color, i) of colorList"
                :key="'color' + i"
                @click="pickColor(d, color.value)"
              >
                <div
                  class="icon-check"
                  :style="`--check-color:${i == 1 ? 'black' : 'white'}`"
                >
                  <font-awesome-icon
                    icon="check"
                    v-if="d.color == color.value"
                  />
                </div>
              </div>
            </div>
            <div
              class="text-error f-14 mt-1"
              v-if="$v.data.$each.$iter[index].color.$error"
            >
              Please select color.
            </div>
          </b-col>
          <b-col cols="6">
            <InputText
              textFloat="Header Name"
              placeholder="Header Text 20 Characters"
              isRequired
              type="text"
              v-model="d.header_name"
              maxLength="20"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 20);'"
              name="header_name"
              :disabled="d.is_action == 0"
              :v="$v.data.$each.$iter[index].header_name"
              :isValidate="$v.data.$each.$iter[index].header_name.$error"
          /></b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <InputText
              textFloat=""
              placeholder="Description Text 20 Charecters"
              customLabel
              isRequired
              type="text"
              v-model="d.description"
              name="description"
              maxLength="20"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 20);'"
              :v="$v.data.$each.$iter[index].description"
              :isValidate="$v.data.$each.$iter[index].description.$error"
              :disabled="d.is_description == 0"
            >
              <template v-slot:textFloat>
                <b-form-checkbox
                  :id="'is_description' + index + formIndex"
                  v-model="d.is_description"
                  :name="'is_description' + index + formIndex"
                  :value="1"
                  :unchecked-value="0"
                  @change="clearValueCheck($event, 'desc', d)"
                >
                  Description Text
                </b-form-checkbox>
              </template>
            </InputText>
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <b-form-checkbox
              :id="'is_price' + index + formIndex"
              class="input-custom"
              v-model="d.is_price"
              :name="'is_price' + index + formIndex"
              :value="1"
              :unchecked-value="0"
              @change="clearValueCheck($event, 'price', d)"
            >
              Price
            </b-form-checkbox>
          </b-col>

          <div class="break-normal"></div>
          <b-col cols="6">
            <InputSelect
              title=""
              name="price_currency"
              isRequired
              :options="priceList"
              v-model="d.price_currency"
              valueField="id"
              textField="value"
              :v="$v.data.$each.$iter[index].price_currency"
              :isValidate="$v.data.$each.$iter[index].price_currency.$error"
              :disabled="d.is_price == 0"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Select Currency --</b-form-select-option
                >
              </template></InputSelect
            ></b-col
          >
          <b-col cols="6">
            <InputText
              textFloat=""
              placeholder="Price"
              type="number"
              v-model="d.price"
              :useDot="false"
              name="price"
              :v="$v.data.$each.$iter[index].price"
              :isValidate="$v.data.$each.$iter[index].price.$error"
              :disabled="d.is_price == 0"
          /></b-col>
          <div class="break-normal"></div>

          <template v-for="(actions, a_index) of actionList[index]">
            <b-col cols="6" :key="'action' + a_index + formIndex + index">
              <b-form-checkbox
                :id="'is_action' + a_index + formIndex + index"
                class="input-custom"
                v-model="actions.is_action"
                :name="'is_action' + a_index + formIndex + index"
                :value="1"
                @change="clearValueCheck($event, 'action', actions)"
                :unchecked-value="0"
              >
                Action {{ a_index + 1 }}
              </b-form-checkbox>
            </b-col>
            <div class="break-normal" :key="'empty' + a_index"></div>
            <b-col cols="6" :key="'action-title-' + a_index">
              <InputText
                textFloat=""
                placeholder="Action Name 15 Characters"
                type="text"
                v-model="actions.action_title_name"
                maxLength="15"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 15);'"
                name="action_title_name"
                :v="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .action_title_name
                "
                :isValidate="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .action_title_name.$error
                "
                :disabled="actions.is_action == 0"
            /></b-col>
            <div class="break-normal" :key="'empty-2' + a_index"></div>
            <b-col cols="6" :key="'action-type' + a_index">
              <InputSelect
                title=""
                name="card-message-type"
                isRequired
                :options="actionType"
                v-model="actions.line_action_type_id"
                valueField="id"
                textField="name"
                :disabled="actions.is_action == 0"
                :v="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .line_action_type_id
                "
                :isValidate="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .line_action_type_id.$error
                "
              >
                <template v-slot:option-first>
                  <b-form-select-option value="0" disabled
                    >Action Type
                  </b-form-select-option>
                </template>
              </InputSelect></b-col
            >
            <b-col cols="6" :key="'action-name-' + a_index">
              <InputText
                textFloat=""
                :placeholder="
                  actions.line_action_type_id == 1
                    ? 'Text 30 Characters'
                    : 'Please Enter URL'
                "
                type="text"
                v-model="actions.action_text"
                name="action_name"
                maxLength="30"
                :oninput="
                  actions.line_action_type_id == 1
                    ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'
                    : ''
                "
                :v="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .action_text
                "
                :isValidate="
                  $v.actionList.$each.$iter[index].$each.$iter[a_index]
                    .action_text.$error
                "
                :disabled="
                  actions.is_action == 0 || actions.line_action_type_id == '0'
                "
            /></b-col>
          </template>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
const imageDetail = [
  {
    format_detail_type: 2,
    is_action: 0,
    line_action_type_id: 1,
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
];
const actionDetail = [
  {
    format_detail_type: 1,
    is_action: 0,
    line_action_type_id: 0,
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
  {
    format_detail_type: 1,
    is_action: 0,
    line_action_type_id: 0,
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
];
const messageData = {
  format_id: 5, // type มี id 2 image, 3 text , 4 image text 5 product message
  number_of_images: 1, // ชื่อ ิbanner
  is_banner: 0, // ลิงค์ text ของ action
  banner: "", //ถ้าติ๊ก ิbanner = 1 ไม่ติ๊ก 0
  color: "", // ใส่ code สี หรือ ชื่อสีก็ได้
  header_name: "", // กรณี product message
  is_description: 0, //ถ้าติ๊ก ิdescription = 1 ไม่ติ๊ก 0 กรณี product message
  description: "", //กรณี product message
  is_price: 0, //ถ้าติ๊ก ิprice = 1 ไม่ติ๊ก 0 กรณี product message
  price_currency: null, // กรณี product message
  price: 0, // กรณี product message
  sort_order: 1,
  details: [...imageDetail, ...actionDetail],
};
function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/|http?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
export default {
  components: {
    ImageUpload,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },

    text: {
      required: true,
      type: Object,
    },
    formIndex: {
      required: false,
    },
    isShowSidebar: {
      required: false,
      type: Boolean,
    },
  },
  validations: {
    data: {
      $each: {
        header_name: {
          required,
        },
        banner: {
          required: requiredIf(function (item) {
            return item.is_banner;
          }),
        },
        color: {
          required: requiredIf(function (item) {
            return item.is_banner;
          }),
        },
        description: {
          required: requiredIf(function (item) {
            return item.is_description;
          }),
        },
        price_currency: {
          required: requiredIf(function (item) {
            return item.price_currency == null && item.is_price;
          }),
        },
        price: {
          required: requiredIf(function (item) {
            return item.price == 0 && item.is_price;
          }),
        },
      },
    },
    imageList: {
      $each: {
        $each: {
          image: {
            required,
          },
        },
      },
    },
    actionList: {
      $each: {
        $each: {
          action_title_name: {
            required: requiredIf(function (item) {
              return item.is_action;
            }),
          },
          line_action_type_id: {
            required: function (item, value) {
              return item == 0 && value.is_action == 1 ? false : true;
            },
          },

          action_text: {
            required: requiredIf(function (item) {
              return item.is_action;
            }),
            url: function (item, data) {
              return data.line_action_type_id == 2 && data.is_action == 1
                ? !validURL(item)
                  ? false
                  : true
                : true;
            },
          },
        },
      },
    },
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.response.format_list));
    if (this.data.length == 0) {
      this.data = [JSON.parse(JSON.stringify(messageData))];
    }
  },
  watch: {
    isShowSidebar(val) {
      if (val) {
        var imageObj = [];
        var actionObj = [];

        for (let index = 0; index < this.response.format_list.length; index++) {
          // const element = array[index];
          imageObj.push(
            JSON.parse(
              JSON.stringify(
                this.response.format_list[index].details.filter(
                  (el) => el.format_detail_type == 2
                )
              )
            )
          );
          actionObj.push(
            JSON.parse(
              JSON.stringify(
                this.response.format_list[index].details.filter(
                  (el) => el.format_detail_type == 1
                )
              )
            )
          );
        }
        if (imageObj.length == 0) {
          this.imageList = [JSON.parse(JSON.stringify(imageDetail))];
        } else {
          this.imageList = imageObj;
        }
        if (actionObj.length == 0) {
          this.actionList = [JSON.parse(JSON.stringify(actionDetail))];
        } else {
          this.actionList = actionObj;
        }

        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
  data() {
    return {
      isShow: false,
      data: [],
      colorList: [
        { text: "#666f86", value: "black" },
        { text: "#ffffff", value: "white" },
        { text: "#eb4e3d", value: "red" },
        { text: "#ed8537", value: "orange" },
        { text: "#00b900", value: "green" },
        { text: "#5b82db", value: "blue" },
      ],
      optType: [1, 2, 3],
      actionType: [
        { id: 1, name: "Text" },
        { id: 2, name: "URL" },
      ],
      priceList: [
        { id: "", value: "None" },
        { id: "¥", value: "¥" },
        { id: "NT $", value: "NT $" },
        { id: "฿", value: "฿" },
        { id: "Rp", value: "Rp" },
        { id: "$", value: "$" },
        { id: "£", value: "£" },
        { id: "₩", value: "₩" },
        { id: "€", value: "€" },
      ],
      actionList: [JSON.parse(JSON.stringify(actionDetail))],
      imageList: [JSON.parse(JSON.stringify(imageDetail))],
    };
  },
  methods: {
    pickColor(obj, val) {
      if (obj.is_banner == 1) {
        obj.color = val;
      }
    },
    setShow(val) {
      this.isShow = val;
    },
    handleChangeImage(image, index, imageIndex, { width, height }) {
      this.imageList[index][imageIndex].image = image;
      this.imageList[index][imageIndex].image_width = width;
      this.imageList[index][imageIndex].image_height = height;
    },
    addCardMessage() {
      this.data.push(JSON.parse(JSON.stringify(messageData)));
      this.imageList.push(JSON.parse(JSON.stringify(imageDetail)));
      this.actionList.push(JSON.parse(JSON.stringify(actionDetail)));
    },
    handleNumberImageChange(obj, n) {
      let data = JSON.parse(JSON.stringify(this.imageList[n]));
      let temp = [];
      for (let index = 0; index < obj.number_of_images; index++) {
        if (data[index]) {
          temp.push({ ...data[index] });
        } else {
          temp.push({ ...imageDetail[0] });
        }
      }
      var tempObj = JSON.parse(JSON.stringify(this.imageList));
      tempObj[n] = temp;

      this.imageList = tempObj;
    },
    onSubmit() {
      this.$v.data.$touch();
      let error = false;
      if (this.$v.data.$error) {
        error = true;
      }
      this.$v.imageList.$touch();
      if (this.$v.imageList.$error) {
        error = true;
      }
      this.$v.actionList.$touch();
      if (this.$v.actionList.$error) {
        error = true;
      }
      if (error) {
        return;
      }
      let n = 0;
      for (const iterator of this.data) {
        iterator.details = [...this.imageList[n], ...this.actionList[n]];
        n++;
      }

      this.response.format_list = JSON.parse(JSON.stringify(this.data));

      return true;
    },
    removeProductList(index) {
      this.data.splice(index, 1);
      this.imageList.splice(index, 1);
      this.actionList.splice(index, 1);
    },
    clearValueCheck(value, type, d) {
      if (type == "banner") {
        d.banner = "";
        d.color = "";
      } else if (type == "price") {
        d.price_currency = null;
        d.price = 0;
      } else if (type == "desc") d.description = "";
      else if (type == "action") {
        d.action_text = "";
        d.action_title_name = "";
        d.line_action_type_id = "0";
      }
    },
    clearValue(type) {
      if (JSON.parse(JSON.stringify(this.response.format_list)).length < 1) {
        this.data = [JSON.parse(JSON.stringify(messageData))];
        this.actionList = [JSON.parse(JSON.stringify(actionDetail))];
        this.imageList = [JSON.parse(JSON.stringify(imageDetail))];
      } else {
        this.data = JSON.parse(JSON.stringify(this.response.format_list));
      }
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-color {
  width: 25px;
  height: 25px;
  background-color: var(--color);
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;
}
.icon-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--check-color);
}
</style>
