<template>
  <div>
    <b-row>
      <b-col md="12" class="content-between">
        <span class="text-main-color f-size-16 font-weight-bold mb-1"
          >Card Message</span
        >
        <span class="text-underline cursor-pointer" @click="addCardMessage"
          ><b>Add</b></span
        >
      </b-col>
    </b-row>

    <div class="card" v-for="(d, index) of data" :key="index">
      <div class="card-body">
        <div class="content-between">
          <span class="text-main-color f-size-16 mb-1"
            >Card Message # {{ index + 1 }}</span
          >
          <span
            class="text-underline cursor-pointer"
            @click="handleDelete(index)"
            v-if="data.length > 1"
            ><font-awesome-icon icon="trash-alt" class="ft-14 ml-2"
          /></span>
        </div>

        <b-row>
          <b-col cols="6">
            <InputSelect
              title="Type"
              name="card-message-type"
              isRequired
              :options="optType"
              v-model="d.format_id"
              valueField="id"
              textField="name"
              @onDataChange="handleChangeFormatType(d.details, $event)"
          /></b-col>
          <template v-if="d.format_id == 1">
            <div class="break-normal"></div>
            <b-col cols="6">
              <ImageUpload
                class="mt-1"
                v-bind:dataFile="d.details[1].image"
                :index="index"
                :imageIndex="0"
                imageRatio="1.11/1"
                :withSize="true"
                :isValid="
                  $v.data.$each.$iter[index].details.$each.$iter[1].image.$error
                "
                :v="$v.data.$each.$iter[index].details.$each.$iter[1].image"
                @handleChangeImage="handleChangeImage"
                desc="(Recommend upload file .png .jpg and less than 10 MB)"
              />
            </b-col>
          </template>
          <template v-if="d.format_id == 2">
            <div class="break-normal"></div>
            <b-col cols="6">
              <ImageUpload
                class="mt-1"
                v-bind:dataFile="d.details[1].image"
                :index="index"
                :imageIndex="0"
                imageRatio="1.11/1"
                :isValid="
                  $v.data.$each.$iter[index].details.$each.$iter[1].image.$error
                "
                :v="$v.data.$each.$iter[index].details.$each.$iter[1].image"
                :withSize="true"
                @handleChangeImage="handleChangeImage"
                desc="(Recommend upload file .png .jpg, aspect ratio 1.11:1 and less than 10 MB)"
              />
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="6">
              <b-form-checkbox
                :id="'is_banner' + index + formIndex"
                class="mb-3"
                v-model="d.is_banner"
                :name="'is_banner' + index + formIndex"
                :value="1"
                :unchecked-value="0"
                @change="clearValueCheck($event, 'banner', d)"
              >
                Banner
              </b-form-checkbox>
            </b-col>
            <b-col cols="6"> <label for="Color">Color</label> </b-col>
            <b-col cols="6">
              <InputText
                textFloat=""
                placeholder="Banner Text 12 Characters"
                type="text"
                v-model="d.banner"
                name="banner_name"
                maxLength="12"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 12);'"
                :v="$v.data.$each.$iter[index].banner"
                :isValidate="$v.data.$each.$iter[index].banner.$error"
                :disabled="d.is_banner == 0"
            /></b-col>
            <b-col cols="6">
              <div
                :class="[
                  'd-flex p-1',
                  { isValid: $v.data.$each.$iter[index].color.$error },
                ]"
                style="column-gap: 1.5rem"
              >
                <div
                  class="picker-color shadow-sm"
                  :style="`--color:${color.text}`"
                  v-for="(color, i) of colorList"
                  :key="'color' + i"
                  @click="pickColor(d, color.value)"
                >
                  <div
                    class="icon-check"
                    :style="`--check-color:${i == 1 ? 'black' : 'white'}`"
                  >
                    <font-awesome-icon
                      icon="check"
                      v-if="d.color == color.value"
                    />
                  </div>
                </div>
              </div>
              <div
                class="text-error f-14 mt-1"
                v-if="$v.data.$each.$iter[index].color.$error"
              >
                Please select color.
              </div>
            </b-col>
          </template>
          <template v-if="d.format_id == 3 || d.format_id == 4">
            <div class="break-normal"></div>

            <b-col cols="6">
              <InputText
                textFloat="Closing Text"
                placeholder="Text 20 Characters"
                type="text"
                maxLength="20"
                :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 20);'"
                v-model="d.closing_text"
                name="closing-text"
                isRequired
                :v="$v.data.$each.$iter[index].closing_text"
                :isValidate="$v.data.$each.$iter[index].closing_text.$error"
                v-if="d.format_id == 3"
              />
              <template v-else>
                <ImageUpload
                  class="mt-1"
                  v-bind:dataFile="d.details[1].image"
                  :index="index"
                  :withSize="true"
                  :imageIndex="0"
                  imageRatio="1.54/1"
                  @handleChangeImage="handleChangeImage"
                  :isValid="
                    $v.data.$each.$iter[index].details.$each.$iter[1].image
                      .$error
                  "
                  :v="$v.data.$each.$iter[index].details.$each.$iter[1].image"
                  desc="(Recommend upload file .png .jpg, aspect ratio 1.54:1 and less than 10 MB)"
                />
              </template>
            </b-col>
          </template>
          <div class="break-normal"></div>
          <b-col cols="6">
            <InputText
              class="mt-3"
              v-if="d.format_id == 4"
              textFloat=""
              placeholder="Action Name 15 Characters"
              type="text"
              isRequired
              maxLength="15"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 15);'"
              v-model="d.details[0].action_title_name"
              name="action_title_name"
              :v="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .action_title_name
              "
              :isValidate="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .action_title_name.$error
              "
          /></b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <b-form-checkbox
              :id="'is_action' + index + formIndex"
              class="mb-3"
              v-model="d.details[0].is_action"
              @change="handleAction($event, d.details[0])"
              :name="'is_action' + index + formIndex"
              :value="1"
              :unchecked-value="0"
            >
              Action
            </b-form-checkbox>
          </b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <InputText
              v-if="d.format_id != 4"
              textFloat=""
              placeholder="Action Name 15 Characters"
              type="text"
              maxLength="15"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 15);'"
              v-model="d.details[0].action_title_name"
              name="action_title_name"
              :v="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .action_title_name
              "
              :isValidate="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .action_title_name.$error
              "
              :disabled="d.details[0].is_action == 0"
          /></b-col>
          <div class="break-normal"></div>
          <b-col cols="6">
            <InputSelect
              title=""
              name="card-message-type"
              isRequired
              :options="actionType"
              v-model="d.details[0].line_action_type_id"
              valueField="id"
              textField="name"
              :disabled="d.details[0].is_action == 0"
              :v="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .line_action_type_id
              "
              :isValidate="
                $v.data.$each.$iter[index].details.$each.$iter[0]
                  .line_action_type_id.$error
              "
            >
              <template v-slot:option-first>
                <b-form-select-option value="0" disabled
                  >Action Type
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <b-col cols="6">
            <InputText
              textFloat=""
              :placeholder="
                d.details[0].line_action_type_id == 1
                  ? 'Text 30 Characters'
                  : 'Please Enter URL'
              "
              type="text"
              v-model="d.details[0].action_text"
              name="action_text"
              maxLength="30"
              :oninput="
                d.details[0].line_action_type_id == 1
                  ? 'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 30);'
                  : ''
              "
              :v="$v.data.$each.$iter[index].details.$each.$iter[0].action_text"
              :isValidate="
                $v.data.$each.$iter[index].details.$each.$iter[0].action_text
                  .$error
              "
              :disabled="
                d.details[0].is_action == 0 ||
                d.details[0].line_action_type_id == '0'
              "
          /></b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, url } from "vuelidate/lib/validators";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
const detail = [
  {
    format_detail_type: 1,
    is_action: 0,
    line_action_type_id: "0",
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
    format_id: 2,
  },
  {
    format_detail_type: 2,
    format_id: 2,
    is_action: 0,
    line_action_type_id: "0",
    action_title_name: "",
    action_text: "",
    image: "",
    image_width: "",
    image_height: "",
  },
];
const messageData = {
  format_id: 2, // type มี id 2 image, 3 text , 4 image text 5 product message
  is_banner: 0, // ลิงค์ text ของ action
  banner: "", //ถ้าติ๊ก ิbanner = 1 ไม่ติ๊ก 0
  color: "", // ใส่ code สี หรือ ชื่อสีก็ได้
  closing_text: "",
  sort_order: 1,
  details: [
    // detail จะมีก็ต่อเมื่อเป็น card message หรือ product message
    ...detail,
  ],
};
function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/|http?:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
export default {
  components: {
    ImageUpload,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
    formIndex: {
      required: false,
    },
  },
  validations() {
    return {
      data: {
        $each: {
          banner: {
            required: requiredIf(function (item) {
              return item.is_banner;
            }),
          },
          color: {
            required: requiredIf(function (item) {
              return item.is_banner;
            }),
          },
          closing_text: {
            required: requiredIf(function (item) {
              return item.format_id == 3;
            }),
          },
          details: {
            $each: {
              action_title_name: {
                required: function (item, value) {
                  return value.format_detail_type == 1 && value.format_id == 4
                    ? item == ""
                      ? false
                      : true
                    : item == "" &&
                      value.format_detail_type == 1 &&
                      value.is_action == 1
                    ? false
                    : true;
                },
              },
              line_action_type_id: {
                required: function (item, value) {
                  return item == "0" &&
                    value.format_detail_type == 1 &&
                    value.is_action == 1
                    ? false
                    : true;
                },
              },
              image: {
                required: requiredIf(function (item, data) {
                  return item.format_detail_type == 2 && item.format_id != 3;
                }),
              },
              action_text: {
                required: requiredIf(function (item) {
                  return item.is_action;
                }),
                url: function (item, data) {
                  return data.line_action_type_id == 2 && data.is_action == 1
                    ? !validURL(item)
                      ? false
                      : true
                    : true;
                },
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.inilizeValue();
  },
  data() {
    return {
      data: [],

      colorList: [
        { text: "#666f86", value: "black" },
        { text: "#ffffff", value: "white" },
        { text: "#eb4e3d", value: "red" },
        { text: "#ed8537", value: "orange" },
        { text: "#00b900", value: "green" },
        { text: "#5b82db", value: "blue" },
      ],
      optType: [
        { id: 2, name: "Image Card" },
        { id: 3, name: "Text Card" },
        { id: 4, name: "Image Text Card" },
      ],
      actionType: [
        { id: 1, name: "Text" },
        { id: 2, name: "URL" },
      ],
    };
  },
  methods: {
    pickColor(obj, val) {
      if (obj.is_banner == 1) {
        obj.color = val;
      }
    },
    handleChangeImage(image, index, imageIndex, { width, height }) {
      this.data[index].details[1].image = image;
      this.data[index].details[1].image_width = width;
      this.data[index].details[1].image_height = height;
    },
    addCardMessage() {
      this.data.push(JSON.parse(JSON.stringify(messageData)));
    },
    onSubmit() {
      this.$v.data.$touch();

      if (this.$v.data.$error) {
        return false;
      }
      this.response.format_list = this.data;

      return true;
    },
    handleAction(val, obj) {
      if (!val) {
        obj.action_text = "";
        obj.action_title_name = "";
        obj.line_action_type_id = "0";
      }
    },
    handleChangeFormatType(obj, val) {
      obj.map((el) => (el.format_id = val));
    },
    handleDelete(index) {
      this.data.splice(index, 1);
    },
    clearValueCheck(value, type, d) {
      if (type == "banner") {
        d.banner = "";
        d.color = "";
      } else if (type == "action") {
      }
    },
    clearValue(type) {
      if (JSON.parse(JSON.stringify(this.response.format_list)).length < 1) {
        this.data = [JSON.parse(JSON.stringify(messageData))];
      } else {
        this.data = JSON.parse(JSON.stringify(this.response.format_list));
      }
    },
    inilizeValue(type) {
      if (this.response.format_list.length > 0) {
        for (const format_list of this.response.format_list) {
          var details = JSON.parse(JSON.stringify(messageData.details));
          const action = format_list.details.filter(
            (el) => el.format_detail_type == 1
          );
          const image = format_list.details.filter(
            (el) => el.format_detail_type == 2
          );
          if (action.length > 0) {
            details[0] = { ...action[0] };
          }
          if (image.length > 0) {
            details[1] = { ...image[0] };
          }
          format_list.details = JSON.parse(JSON.stringify(details));
        }

        this.data = JSON.parse(JSON.stringify(this.response.format_list));

        // this.data.details = JSON.parse(JSON.stringify(this.response.format_list.details.length));
      }

      if (this.data.length == 0) {
        this.data = [JSON.parse(JSON.stringify(messageData))];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-color {
  width: 25px;
  height: 25px;
  background-color: var(--color);
  border-radius: 0.25rem;
  cursor: pointer;
  position: relative;
}
.icon-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--check-color);
}
.isValid {
  border: 1px solid red;
  padding: 0.25rem;
  width: max-content;
  border-radius: 0.25rem;
}
</style>
