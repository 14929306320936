<template>
  <div class="pl-3 pr-3 pb-3">
    <b-tabs class="tab-menu-list" v-model="tabIndex">
      <b-tab
        :title="`All (${userData.result_count || userData.all_count})`"
        active
        class="text-nowrap"
      >
        <b-row class="padding-table">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div>{{ data.item.sender_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.sender_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 1"
                    icon="spinner"
                    class="icon-pending ml-2"
                  />
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 2"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.message_receiver === 3"
                    class="icon-unsuccess ml-2"
                  />
                  <span v-if="data.item.message_receiver === 4">-</span>
                </div>
              </template>
              <template v-slot:cell(is_goal)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    v-if="data.item.is_goal === 1"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.is_goal === 0"
                    class="icon-unsuccess ml-2"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-0 ml-sm-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="4"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="userData.result_count === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>
                {{
                  filter.take * filter.page > userData.all_count
                    ? userData.all_count
                    : (filter.take * filter.page) | numeral("0,0")
                }}</span
              >
              of
              <span>{{ userData.result_count || userData.all_count }}</span>
              entries
            </p>
          </b-col>

          <b-col
            cols="12"
            md="8"
            sm="8"
            class="form-inline justify-content-center justify-content-sm-end"
          >
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="userData.result_count || userData.all_count"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="my-2 my-sm-0"
              ></b-pagination>
            </div>
            <div class="break"></div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.take"
                class="page-take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Email (${userData.email_count})`">
        <b-row class="padding-table">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div>{{ data.item.sender_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.sender_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 1"
                    icon="spinner"
                    class="icon-pending ml-2"
                  />
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 2"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.message_receiver === 3"
                    class="icon-unsuccess ml-2"
                  />
                  <span v-if="data.item.message_receiver === 4">-</span>
                </div>
              </template>
              <template v-slot:cell(is_goal)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <!--  -->

                  <font-awesome-icon
                    v-if="data.item.is_goal === 1"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.is_goal === 0"
                    class="icon-unsuccess ml-2"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-0 ml-sm-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="4"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="userData.email_count === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>{{
                filter.take * filter.page > userData.email_count
                  ? userData.email_count
                  : (filter.take * filter.page) | numeral("0,0")
              }}</span>
              of <span>{{ userData.email_count }}</span> entries
            </p>
          </b-col>
          <b-col
            cols="12"
            md="8"
            sm="8"
            class="form-inline justify-content-center justify-content-sm-end"
          >
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="userData.email_count"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="my-2 my-sm-0"
              ></b-pagination>
            </div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.take"
                class="page-take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`SMS (${userData.sms_count})`">
        <b-row class="padding-table">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div>{{ data.item.sender_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.sender_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 1"
                    icon="spinner"
                    class="icon-pending ml-2"
                  />
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 2"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.message_receiver === 3"
                    class="icon-unsuccess ml-2"
                  />
                  <span v-if="data.item.message_receiver === 4">-</span>
                </div>
              </template>
              <template v-slot:cell(is_goal)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <!--  -->

                  <font-awesome-icon
                    v-if="data.item.is_goal === 1"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.is_goal === 0"
                    class="icon-unsuccess ml-2"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-0 ml-sm-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="4"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="userData.sms_count === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>{{
                filter.take * filter.page > userData.sms_count
                  ? userData.sms_count
                  : (filter.take * filter.page) | numeral("0,0")
              }}</span>
              of <span>{{ userData.sms_count }}</span> entries
            </p>
          </b-col>
          <b-col
            cols="12"
            md="8"
            sm="8"
            class="form-inline justify-content-center justify-content-sm-end"
          >
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="userData.sms_count"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="my-2 my-sm-0"
              ></b-pagination>
            </div>
            <div class="break"></div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.take"
                class="page-take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Line (${userData.line_count})`">
        <b-row class="padding-table">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div>{{ data.item.sender_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.sender_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 1"
                    icon="spinner"
                    class="icon-pending ml-2"
                  />
                  <font-awesome-icon
                    v-if="data.item.message_receiver === 2"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.message_receiver === 3"
                    class="icon-unsuccess ml-2"
                  />
                  <span v-if="data.item.message_receiver === 4">-</span>
                </div>
              </template>
              <template v-slot:cell(is_goal)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <!--  -->

                  <font-awesome-icon
                    v-if="data.item.is_goal === 1"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.is_goal === 0"
                    class="icon-unsuccess ml-2"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-0 ml-sm-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="4"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="userData.line_count === 0" id="from-row">0</span>
              <span v-else>{{ showing }}</span> to
              <span>{{
                filter.take * filter.page > userData.line_count
                  ? userData.line_count
                  : (filter.take * filter.page) | numeral("0,0")
              }}</span>
              of <span>{{ userData.line_count }}</span> entries
            </p>
          </b-col>
          <b-col
            cols="12"
            md="8"
            sm="8"
            class="form-inline justify-content-center justify-content-sm-end"
          >
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="userData.line_count"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="my-2 my-sm-0"
              ></b-pagination>
            </div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.take"
                class="page-take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="`Failed To Send (${userData.not_succes_count})`">
        <b-row class="padding-table">
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="userData.result"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(sender_time)="data">
                <div>{{ data.item.sender_time | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.sender_time | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(message_receiver)="data">
                <div class="d-flex justify-content-center">
                  {{ data.item.channel_sender }}
                  <span v-if="data.item.message_receiver === 1">
                    <font-awesome-icon icon="spinner" class="icon-pending"
                  /></span>
                  <span v-if="data.item.message_receiver === 2">
                    <font-awesome-icon icon="check" class="icon-check"
                  /></span>
                  <span v-if="data.item.message_receiver === 3"
                    ><font-awesome-icon icon="times" class="icon-unsuccess"
                  /></span>
                </div>
              </template>
              <template v-slot:cell(is_goal)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <!--  -->

                  <font-awesome-icon
                    v-if="data.item.is_goal === 1"
                    icon="check"
                    class="icon-check ml-2"
                  />
                  <font-awesome-icon
                    icon="times"
                    v-if="data.item.is_goal === 0"
                    class="icon-unsuccess ml-2"
                  />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-0 ml-sm-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
            sm="4"
            class="form-inline justify-content-center justify-content-sm-start"
          >
            <p class="mb-0 p-gray">
              Showing
              <span v-if="userData.not_succes_count === 0" id="from-row"
                >0</span
              >
              <span v-else>{{ showing }}</span> to
              <span>{{
                filter.take * filter.page > userData.not_succes_count
                  ? userData.not_succes_count
                  : (filter.take * filter.page) | numeral("0,0")
              }}</span>
              of <span>{{ userData.not_succes_count }}</span> entries
            </p>
          </b-col>
          <b-col
            cols="12"
            md="8"
            sm="8"
            class="form-inline justify-content-center justify-content-sm-end"
          >
            <div>
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.take"
                @change="pagination"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                class="my-2 my-sm-0"
              ></b-pagination>
            </div>
            <div class="ml-1">
              <b-form-select
                v-model="filter.take"
                class="page-take"
                @change="hanndleChangePerpageAll"
                :options="pageOptions"
              ></b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: {
    userData: {
      required: true,
      type: Object,
    },
    fields: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    showingTo: {
      required: false,
      type: Number,
    },
    rows: {
      required: false,
      type: Number,
    },
  },
  watch: {
    tabIndex(val) {
      this.filter.page = 1;
      let value = val == 4 ? -1 : val;
      this.$emit("chkTabIndex", value);
    },
  },
  data() {
    return {
      tabIndex: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      toShow: this.showingTo,
      showing: 1,
    };
  },
  methods: {
    hanndleChangePerpageAll() {
      this.$emit("changePage");
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("getDataInPagination", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-table {
  padding-top: 1px;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.icon-check {
  color: green;
}
.icon-pending {
  color: #000000;
}
.icon-unsuccess {
  color: red;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .tab-menu-list {
  div {
    ul {
      flex-wrap: nowrap;

      overflow-x: auto;
      overflow-y: hidden;
      li {
        min-width: max-content;
        overflow-y: hidden;
      }
    }
  }
}
</style>
