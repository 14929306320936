<template>
  <div>
    <b-row>
      <b-col md="12">
        <span class="title-img">Image </span>
      </b-col>
      <b-col md="12">
        <ImageUpload
          class="mt-1"
          :v="v.image_url"
          v-bind:dataFile="text.image_url"
          @handleChangeImage="handleChangeImage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputTextAreaVariable from "@/components/inputs/InputTextAreaVariable";
import UploadFile from "@/components/inputs/UploadFile";
import ImageUpload from "@/components/automation/detail/LINE/TypeMessage/ImageUpload";
export default {
  components: {
    ImageUpload,
    InputTextAreaVariable,
    UploadFile,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    text: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      respLine: this.response,
    };
  },
  methods: {
    handleChangeImage(image) {
      this.text.image_url = image;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-img {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
