<template>
  <div>
    <b-row>
      <b-col md="12">
        <span class="text-main-color f-size-16 font-weight-bold mb-1"
          >Images</span
        >
      </b-col>
      <b-col md="12">
        <ImageListUpload
          class="mt-1"
          :v="v"
          v-bind:dataList="dataList"
          :response="response"
          @handleChangeImageList="handleChangeImageList"
          :withSize="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ImageListUpload from "@/components/automation/detail/LINE/TypeMessage/ImageListUpload";
export default {
  components: {
    ImageListUpload,
  },
  props: {
    response: {
      required: true,
      type: Object,
    },
    v: {
      required: false,
      type: Object,
    },
    dataImage: {
      required: true,
      type: Array,
    },
    id: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      dataList: this.dataImage,
    };
  },
  created() {
    if (this.id !== "0") {
      const findNull = this.response.communication_value.some(
        (el) => !el.image_url
      );
      if (findNull) {
        this.dataList = [];
      } else {
        this.dataList = this.response.communication_value;
      }
    } else {
      this.dataList = [];
    }
  },
  methods: {
    handleChangeImageList(imageList) {
      this.response.communication_value = imageList;
    },
  },
};
</script>

<style lang="scss" scoped></style>
